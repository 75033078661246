import React, { useEffect } from 'react';
import { Box, Paper, Toolbar, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchWithdrawals } from '@action/withdrawal.action';
import {
  selectIsFetchingWithdrawals,
  selectWithdrawals,
} from '@selector/withdrawal.selector';
import AppLoader from '@component/AppLoader';

import TableComponent from './Table';

const Withdrawals: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, currentPage, totalRecords } = useAppSelector(selectWithdrawals);
  const isFetchingWithdrawals = useAppSelector(selectIsFetchingWithdrawals);

  useEffect(() => {
    dispatch(fetchWithdrawals({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Withdrawals</Typography>

        <Box width={150}></Box>
      </Toolbar>

      {isFetchingWithdrawals && !data && (
        <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
          <AppLoader size={10} />
        </Paper>
      )}

      {!isFetchingWithdrawals && !data?.length && (
        <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
          <Typography>No withdrawals to display</Typography>
        </Paper>
      )}

      {data?.length && (
        <TableComponent
          data={data}
          currentPage={currentPage}
          totalRecords={totalRecords}
        />
      )}
    </>
  );
};

export default Withdrawals;
