import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import WithdrawalAttributes from '@interface/withdrawal.interface';
import WithdrawalStatus from '@enum/withdrawalStatus.enum';
import LoadingButton from '@component/LoadingButton';
import { enqueueWithdrawal } from '@action/withdrawal.action';
import { useAppDispatch } from '@hook/hooks.hook';

const TableComponent = ({
  data,
  currentPage,
  totalRecords,
}: {
  data: WithdrawalAttributes[];
  currentPage: number;
  totalRecords: number;
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    // Handle page change
    console.log('Page changed to:', newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // Reset to the first page when rows per page change
    handleChangePage(null, 0);
  };

  const [selectedWithdrawal, setSelectedWithdrawal] = useState('');

  const dialogData = data.find((row) => row.id === selectedWithdrawal) || {};

  return (
    <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>App Name</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              ({
                id,
                status,
                createdAt,
                amount,
                currency,
                company,
                app,
              }: WithdrawalAttributes) => (
                <TableRow
                  key={id}
                  onClick={() => setSelectedWithdrawal(id as string)}
                >
                  <TableCell>{status}</TableCell>
                  <TableCell>
                    {dayjs(createdAt).format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell>{company?.name}</TableCell>
                  <TableCell>{app?.name}</TableCell>
                  <TableCell>
                    {amount} {currency}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords || 0}
        page={(currentPage ?? 1) - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 200]}
        labelRowsPerPage="Items per page"
      />

      {selectedWithdrawal && (
        <WithdrawalDialog
          open={Boolean(selectedWithdrawal)}
          onClose={() => {
            setSelectedWithdrawal('');
          }}
          {...dialogData}
        />
      )}
    </Paper>
  );
};

const WithdrawalDialog = ({ open, onClose, ...others }: any) => {
  const { id, company, app, amount, currency, status, appWallet } =
    others as WithdrawalAttributes;

  const isPending = status === WithdrawalStatus.PENDING;
  const dispatch = useAppDispatch();
  const [isEnqueuing, setIsEnqueuing] = useState(false);

  const handleEnqueue = async () => {
    setIsEnqueuing(true);
    try {
      await dispatch(enqueueWithdrawal(id as string));
    } catch (e: any) {
      console.error('Enqueue error:', e);
    }

    setIsEnqueuing(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Withdrawal</DialogTitle>

      <DialogContent dividers>
        <Stack direction="row" justifyContent="space-between" width={400}>
          <Stack gap={1}>
            <Typography variant={'h6'}>
              {company?.name}{' '}
              <span style={{ fontSize: 12 }}>({company?.status})</span>
            </Typography>
            {app?.name && (
              <Typography variant={'subtitle1'}>
                {app?.name}{' '}
                <span style={{ fontSize: 12 }}>({app?.status})</span>
              </Typography>
            )}
            <Typography variant={'subtitle2'}>{status}</Typography>
          </Stack>

          <Stack gap={3}>
            <Stack>
              <Typography variant={'subtitle2'}>Wallet Balance</Typography>
              <Typography variant={'body2'}>
                {appWallet?.amount} {appWallet?.currency}
              </Typography>
            </Stack>

            <Stack>
              <Typography variant={'subtitle2'}>Withdrawal Amount</Typography>
              <Typography variant="body2">
                {amount} {currency}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      {isPending && (
        <DialogActions>
          <LoadingButton isLoading={isEnqueuing} onClick={handleEnqueue}>
            Enqueue
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TableComponent;
