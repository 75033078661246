import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import PaginatedResult from '@interface/paginatedResult.interface';
import buildUrl from '@util/buildUrl.util';
import WithdrawalAttributes from '@interface/withdrawal.interface';

const URL = `${API_URL}/admin`;

export const fetchWithdrawals = createAsyncThunk(
  `${SLICE_NAME}/fetchWithdrawals`,
  async ({
    page = 1,
    limit = 50,
  }: {
    page?: number;
    limit?: number;
  }): Promise<PaginatedResult<WithdrawalAttributes>> => {
    const result = await axios(
      buildUrl(`${API_URL}/admin/withdrawals`, {
        page,
        limit,
      }),
    );
    return result.data;
  },
);

export const enqueueWithdrawal = createAsyncThunk(
  `${SLICE_NAME}/enqueueWithdrawal`,
  async (withdrawalId: string): Promise<string> => {
    await axios.post(`${URL}/enqueue-withdrawal/${withdrawalId}`);
    return withdrawalId;
  },
);
