import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import Spinner from '@component/Spinner';
import { SpinnerVariant } from '@component/Spinner/Spinner';

const BasicDetails = ({
  energy,
  address,
  bandwidth,
  trxBalance,
  usdcBalance,
  usdtBalance,
  exchangeRate,
  isFetchingFeeWalletAddress,
}: {
  energy: number;
  address?: string;
  bandwidth: number;
  trxBalance: number;
  usdcBalance: number;
  usdtBalance: number;
  exchangeRate: number;

  isFetchingFeeWalletAddress: boolean;
}) => {
  return (
    <Box py={2}>
      <Typography variant="h5" mb={2}>
        Wallet Details
      </Typography>

      <Stack gap={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">Address: {address}</Typography>
          {isFetchingFeeWalletAddress && (
            <Spinner variant={SpinnerVariant.ROTATE} size={10} />
          )}
        </Stack>

        <Typography variant="body2">
          TRX Balance: {trxBalance / 1_000_000} TRX ($
          {((trxBalance / 1_000_000) * exchangeRate).toFixed(2)})
        </Typography>

        <Typography variant="body2">
          USDT Balance: {usdtBalance} USDT
        </Typography>
        <Typography variant="body2">
          USDC Balance: {usdcBalance} USDC
        </Typography>
        <Typography variant="body2">Available Energy: {energy}</Typography>
        <Typography variant="body2">
          Available Bandwidth: {bandwidth}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BasicDetails;
