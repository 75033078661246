import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@store';
import { SLICE_NAME } from '@variable';

export const selectWallets = (state: RootState) => state[SLICE_NAME].wallets;

export const selectIsFetchingFeeWalletAddress = (state: RootState) =>
  state[SLICE_NAME].isFetchingFeeWalletAddress;

export const selectFeeWalletAddress = (state: RootState) =>
  state[SLICE_NAME].feeWalletAddress;

export const selectWalletById = createSelector(
  selectWallets,
  (_: RootState, id: string) => id,
  (wallets, id) => wallets.find((wallet) => wallet.id === id),
);
