import React, { useEffect } from 'react';
import { Box, Divider } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectFeeWalletAddress,
  selectIsFetchingFeeWalletAddress,
} from '@selector/wallet.selector';
import { fetchFeeWalletAddress } from '@action/wallet.action';
import AppLoader from '@component/AppLoader';

import BasicDetails from './BasicDetails';
import Stake from './Stake';

const FeeWalletManagement = () => {
  const dispatch = useAppDispatch();
  const {
    energy,
    address,
    bandwidth,
    trxBalance,
    usdcBalance,
    usdtBalance,
    exchangeRate,
    frozen,
  } = useAppSelector(selectFeeWalletAddress);
  const isFetchingFeeWalletAddress = useAppSelector(
    selectIsFetchingFeeWalletAddress,
  );

  useEffect(() => {
    dispatch(fetchFeeWalletAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoader = Boolean(isFetchingFeeWalletAddress && !address);

  return showLoader ? (
    <AppLoader />
  ) : (
    <Box>
      <BasicDetails
        energy={energy}
        address={address}
        bandwidth={bandwidth}
        trxBalance={trxBalance}
        usdcBalance={usdcBalance}
        usdtBalance={usdtBalance}
        exchangeRate={exchangeRate}
        isFetchingFeeWalletAddress={isFetchingFeeWalletAddress}
      />

      <Divider />

      <Stake
        balance={trxBalance}
        frozen={frozen || []}
        exchangeRate={exchangeRate}
      />
    </Box>
  );
};

export default FeeWalletManagement;
