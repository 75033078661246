import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import Blockchain from '@mui/icons-material/CurrencyBitcoin';
import CachedIcon from '@mui/icons-material/Cached';

import { selectWalletById } from '@selector/wallet.selector';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { hydrateWallet, sendTrx } from '@action/wallet.action';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import LoadingButton from '@component/LoadingButton';

const validateUsdtUsdcAddress = (
  address: string,
  cryptocurrency: string,
): boolean => {
  switch (cryptocurrency) {
    case CryptoCurrency.BTC:
      return (
        /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address) ||
        /^bc1[ac-hj-np-z02-9]{39,59}$/.test(address)
      );
    case CryptoCurrency.ETH:
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    case CryptoCurrency.USDT:
    case CryptoCurrency.USDC:
    case CryptoCurrency.TRX:
      return /^T[a-zA-Z0-9]{33}$/.test(address); // TRC20 address format
    default:
      return false;
  }
};

const ManageWallet = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const open = Boolean(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [withdrawalCrypto, setWithdrawalCrypto] = useState('');
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalAddress, setWithdrawalAddress] = useState('');

  const { address, usdtBalance, usdcBalance, trxBalance } =
    useAppSelector((state) => selectWalletById(state, id)) || {};

  const openRow = () => {
    window.open(`https://tronscan.org/#/address/${address}`, '_blank');
  };

  const handleSetAmount = useCallback((event: any) => {
    setWithdrawalAmount(event.target.value);
  }, []);

  const handleSetWithdrawalAddress = useCallback((event: any) => {
    setWithdrawalAddress(event.target.value);
  }, []);

  const handleSelectCrypto = useCallback((event: any) => {
    setWithdrawalCrypto(event.target.value);
  }, []);

  let balance = 0;

  if (withdrawalCrypto === CryptoCurrency.TRX) balance = trxBalance as number;
  if (withdrawalCrypto === CryptoCurrency.USDT) balance = usdtBalance as number;
  if (withdrawalCrypto === CryptoCurrency.USDC) balance = usdcBalance as number;

  const inputsAreInvalid =
    isSubmitting ||
    !withdrawalCrypto ||
    withdrawalAmount < 1 ||
    withdrawalAmount > balance ||
    !withdrawalAddress ||
    !validateUsdtUsdcAddress(withdrawalAddress, withdrawalCrypto as any);

  const handleSubmit = useCallback(async () => {
    if (inputsAreInvalid) return;
    setIsSubmitting(true);

    if (withdrawalCrypto === CryptoCurrency.TRX) {
      await dispatch(
        sendTrx({
          amount: Number(withdrawalAmount),
          addressId: id,
          toAddress: withdrawalAddress,
        }),
      );
    }

    setIsSubmitting(false);
  }, [
    inputsAreInvalid,
    withdrawalAmount,
    id,
    withdrawalAddress,
    withdrawalCrypto,
    dispatch,
  ]);
  const [isHydrating, setIsHydrating] = useState(false);

  const onHydrateWallet = useCallback(async () => {
    setIsHydrating(true);
    await dispatch(hydrateWallet(id));
    setIsHydrating(false);
  }, [dispatch, id]);

  return open ? (
    <Dialog open={Boolean(id)} onClose={onClose}>
      <DialogTitle>Wallet Details</DialogTitle>

      <Stack sx={{ position: 'absolute', top: 10, right: 10 }} direction="row">
        <IconButton disabled={isHydrating} onClick={onHydrateWallet}>
          <CachedIcon />
        </IconButton>

        <IconButton onClick={openRow}>
          <Blockchain />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack gap={1} mt={1} mb={4}>
          <Typography variant="body2">Address: {address}</Typography>
          <Typography variant="body2">USDT Balance: {usdtBalance}</Typography>
          <Typography variant="body2">USDC Balance: {usdcBalance}</Typography>
          <Typography variant="body2">TRX Balance: {trxBalance}</Typography>
        </Stack>

        <Stack gap={1}>
          <FormControl fullWidth>
            <InputLabel>Crypto</InputLabel>
            <Select onChange={handleSelectCrypto}>
              {['TRX'].map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {withdrawalCrypto && (
            <>
              <TextField
                name="amount"
                type="number"
                placeholder={`${balance} TRX`}
                onChange={handleSetAmount}
              />
              <TextField
                name="address"
                placeholder={`Withdrawal address`}
                onChange={handleSetWithdrawalAddress}
              />
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={inputsAreInvalid}
        >
          Send {withdrawalCrypto}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default ManageWallet;
