import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import LoadingButton from '@component/LoadingButton';
import { stakeTrx } from '@action/wallet.action';
import { useAppDispatch } from '@hook/hooks.hook';

const STAKE_REASON = ['BANDWIDTH', 'ENERGY'];

const Stake = ({
  balance,
  frozen,
  exchangeRate,
}: {
  balance: number;
  exchangeRate: number;
  frozen: { type: string; amount: number }[];
}) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState(1);
  const [stakeReason, setStakeReason] = useState('');
  const [duration, setDuration] = useState(3);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'amount') setAmount(Number(e.target.value));
    else if (e.target.name === 'stakeReason') setStakeReason(e.target.value);
    else if (e.target.name === 'duration') setDuration(Number(e.target.value));
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await dispatch(stakeTrx({ amount, duration, stakeReason }));
    setIsSubmitting(false);
  }, [amount, dispatch, duration, stakeReason]);

  const disableBtn = !amount || amount < 1 || amount > balance || !stakeReason;

  return (
    <Box py={2}>
      <Typography variant="h5" mb={2}>
        Staking
      </Typography>

      <Stack gap={1} mb={4}>
        {frozen.map((curr, index) => (
          <Typography variant="body2" key={index}>
            Staked for {(curr.type || 'Bandwidth').toLowerCase()}:{' '}
            {(curr.amount || 0) / 1_000_000} TRX ($
            {(((curr.amount || 0) / 1_000_000) * exchangeRate).toFixed(2)})
          </Typography>
        ))}
      </Stack>

      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <TextField
            name="amount"
            type="number"
            value={amount}
            onChange={handleChange}
            placeholder={`${balance} TRX`}
            inputProps={{ min: 1, max: balance }}
          />

          <FormControl sx={{ width: 196 }}>
            <InputLabel>Stake Reason</InputLabel>
            <Select
              name="stakeReason"
              value={stakeReason}
              onChange={handleChange as any}
            >
              {STAKE_REASON.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            type="number"
            name="duration"
            value={duration}
            placeholder={`3 days`}
            inputProps={{ min: 3 }}
            onChange={handleChange}
          />

          <LoadingButton
            onClick={onSubmit}
            variant="contained"
            disabled={disableBtn}
            isLoading={isSubmitting}
          >
            Stake
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Stake;
