import { Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';

import AppLoader from '@component/AppLoader';
import { useAuth } from '@hook/useAuth.hook';
import BasicInfoSection from '@page/Settings/BasicInfoSection';
import SeedUser from '@page/Settings/SeedUser';

const Settings = () => {
  const { isAuthenticating } = useAuth();

  return isAuthenticating ? (
    <AppLoader />
  ) : (
    <>
      <Toolbar>
        <Typography variant="h4">Settings</Typography>
      </Toolbar>
      <Stack gap={4}>
        <BasicInfoSection />

        <SeedUser />
      </Stack>
    </>
  );
};

export default Settings;
