import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';

import Login from '@page/Login';
import UnprotectedRoute from '@component/UnprotectedRoute';
import ProtectedRoute from '@component/ProtectedRoute';
import AuthLayout from '@component/AuthLayout';
import DashboardLayout from '@component/DashboardLayout';
import ErrorBoundary from '@component/ErrorBoundary';
import Settings from '@page/Settings';
import Requests from '@page/Requests';
import Companies from '@page/Companies';
import Apps from '@page/Apps';
import Overview from '@page/Overview';
import Users from '@page/Users';
import Wallets from '@page/Wallets';
import Company from '@page/Company';
import ViewCreatedApp from '@page/ViewCreatedApp';
import TaxesFees from '@page/TaxesFees';
import Withdrawals from '@page/Withdrawals';
import FeeWalletManagement from '@page/FeeWalletManagement';

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <UnprotectedRoute>
        <AuthLayout />
      </UnprotectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Login />,
      },
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <ErrorBoundary>
          <DashboardLayout />
        </ErrorBoundary>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'requests',
        element: <Requests />,
      },
      {
        path: 'companies',
        element: <Companies />,
      },
      {
        path: 'fee-wallet',
        element: <FeeWalletManagement />,
      },
      {
        path: 'withdrawals',
        element: <Withdrawals />,
      },
      {
        path: 'companies/:id',
        element: <Company />,
      },
      {
        path: 'companies/:id/app/:appId',
        element: <ViewCreatedApp />,
      },
      {
        path: 'apps',
        element: <Apps />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'tax-fees',
        element: <TaxesFees />,
      },
      {
        path: 'wallets',
        element: <Wallets />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  },
]);

export default router;
