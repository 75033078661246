import { SLICE_NAME } from '@variable';
import { RootState } from '@store';

export const selectUser = (state: RootState) => state[SLICE_NAME].user;

export const selectGlobalLoaderIsLoading = (state: RootState) =>
  state[SLICE_NAME].isFetchingCompanies ||
  state[SLICE_NAME].isFetchingFeeWalletAddress ||
  state[SLICE_NAME].isFetchingTaxesAndFees;

export const selectIsAuthenticating = (state: RootState) =>
  state[SLICE_NAME].isAuthenticating;

export const selectIsAuthenticated = (state: RootState) =>
  state[SLICE_NAME].isAuthenticated;
